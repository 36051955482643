:root {
  --primaryColor: #000687;
  --white: #fff;
  --headerHeight: 90px;
}

* {
  /* box-sizing: content-box !important; */
}

body,
#root,
.MuiTypography-root {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

html,
#root,
body {
  background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Alexandria", sans-serif !important;
}

.useLightColor {
  color: var(--lightColor);
}

/* GENERAL */
.MuiButton-root {
  min-width: 100px;
}

svg {
  max-width: 100%;
}

a {
  text-decoration: none !important;
  color: inherit;
}


/*APP STYLES*/
.app-wrapper {
  min-height: 100%;
}

/* styles.css */
html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}


main {
  margin: 0;
  padding: 0;
  display: block;
  height: calc(100% - var(--headerHeight));
  box-sizing: content-box;
}


.auth-page .footer-wrapper {
  display: none;
}

.auth-page header {
  display: none;
}

.auth-page main {
  height: 100vh;

}

.header-menu {
  display: flex;
  padding: 0.8rem 1rem;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  background-color: #fff;
}

.header-menu nav a {
  font-weight: 500;
}

.header-menu nav,
.header-menu ul {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.header-menu ul a {}

#root .btn-primary,
#root .btn-rounded {
  border-radius: 50px;
}

#root .btn-white {}

.page-section {
  position: relative;
  padding: 5rem 0 2.5rem;
}

.pg_title {
  /* text-align: center; */
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  font-size: 2rem;
}

.testimonial-slider {
  margin: 2rem 0;
}

button.btn-primary {
  border-radius: 35px;
  padding: 1rem 1.5rem;
}

/*******/
.header-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
}

.header-topbar a {
  text-decoration: none;
  font-size: 0.8rem;
}

.hero-section {
  height: 40vh;
  min-height: 500px;
  display: flex;
  padding: 2rem;
  align-items: center;
  position: relative;
}

.hero-section .bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: url("../assets/bg.jpg") no-repeat center/cover;
  clip-path: polygon(0% 0%, 100% 0%, 60% 60%, 0% 100%);
  height: 100%;
}

.hero-section .info {
  margin-left: auto;
  right: 5%;
  height: 75%;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  background: #fff;
  border-radius: 1rem;
  justify-content: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-top: 3rem;
  gap: 2rem;
  align-items: center;
}

.testimonial__section {
  padding: 2rem;
  padding-top: 6rem;
}

#root .testimonial_item {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}




.the-process {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  /* margin-top: 2.5rem; */
}

.process_section .inner {
  display: flex;
}

.process_elements {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80%;
  column-gap: 5rem;
}

.process-item {
  width: 10rem;
  height: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transform: rotate(-45deg);
  background: var(--primaryColor);
  color: #fff;
}

.process-item .inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.3rem;
  transform: rotate(45deg);
}

.items-center {
  display: flex;
  align-items: center;
}

.process-item img {
  width: 2.5rem;
  height: 2.5rem;
}

.the-process>p {
  text-align: center;
  max-width: 70%;
}

.tech_section .icon {
  margin: 0.7rem 0;
}

/* Auth */

.authContainer {
  height: inherit;
}

.authContainer .right,
.authContainer .left {
  height: 100%;
}

.authContainer .right {
  border-radius: 16px;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(234, 235, 239, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(234, 235, 239, 0.68);
}

div.round {
  background: rgba(0, 6, 135, 0.88);
  border-radius: 100%;
  height: 14rem;
  width: 14rem;
  position: relative;
}

div.round::after {
  content: '';
  display: block;
  height: 90%;
  position: absolute;
  bottom: -40%;
  left: -25%;
  margin-top: auto;
  width: 150%;
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.2);
}

.authContainer .left {
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.authForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.reg-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 500px;
  text-align: center;
}

.reg-success .icon {
  height: 200px;
  width: 200px;
}

svg {
  max-width: 100%;
  max-height: 100%;
}

/* Dashboard */


.footer-wrapper {
  width: 100%;
}

.footer {
  width: 100%;
  padding: 3rem 0;
}

.footer a {}

#root .inner {
  margin: 0 auto;
  max-width: 80%;
}

.footer .footer-menu-title {
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
}

.footer .footer-menu-title.custom-title {
  margin: 20px 0;
}

.footer-menu-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.footer-wrapper .copyright {
  padding: 1rem 0;
}

.use-mask {
  position: relative;
  mask: url(../assets/mask.svg) no-repeat center;
  --webkit-mask: url(../assets/mask.svg) no-repeat center;
  display: none;
}

.hero-section-2 {
  min-height: 700px;
  height: 70vh;
  width: 100%;
  position: relative;
  margin-top: 0;
}

.hero-section-2 h2 {
  font-family: "Alexandria", sans-serif !important;
  font-weight: 600;
  line-height: 1.2;
  font-style: normal;
}

.hero-section-2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fafafa;
  z-index: 0;
  opacity: 0.1;
  background-color: white;
  background: radial-gradient(100% 50% at 50% 0%, rgba(0, 163, 255, 0.13) 0%, rgba(0, 163, 255, 0) 50%, rgba(0, 163, 255, 0) 100%);
  background-color: white;
  background: radial-gradient(100% 50% at 50% 0%, rgba(0, 163, 255, 0.13) 0%, rgba(0, 163, 255, 0) 50%, rgba(0, 163, 255, 0) 100%);
}

.hero-section-2 .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  background: transparent;
  /* background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: white;
  background: radial-gradient(100% 50% at 50% 0%, rgba(0, 163, 255, 0.13) 0%, rgba(0, 163, 255, 0) 50%, rgba(0, 163, 255, 0) 100%); */
}

.relative {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
}

.absolute {
  position: absolute;
  height: 100%;
  width: 100%;
  background: radial-gradient(#D6DEEF 1px, transparent 1px);
  background-size: 16px 16px;
  mask-image: radial-gradient(ellipse 50% 50% at 50% 50%, #000 70%, transparent 100%);
}

.hero-section-2 .content .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2rem;
  padding: 3rem 2rem 4rem 5rem;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
}

.hero-section-2 .info,
.hero-section-2 .use-mask {
  height: 100%;
}

.hero-section-2 .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 5rem;
  align-items: center;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  z-index: 4;
}

.hero-section-2 mark,
.hero-section-2 .Typewriter {
  display: inline-block;
  min-width: 250px;
}

.info h2 {
  font-size: 3.5rem;
}

mark {
  background: rgba(145, 152, 243, 0.2);

  border-radius: 15px;
  padding: 5px;
}

/* .hero-section-2 {
  background: linear-gradient(180deg, #ffffff, #f4f5fe, #f2f3fe, #f8f9fe);
} */

/* .hero-section-2 .bg {
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  background: linear-gradient(115deg, #62cff4, #ff00ff);
} */

.header-menu {
  position: absolute;
  right: 0;
  left: 0;
  background: #f3f6fb;
  background: transparent;
}

.home .header-menu {
  background: transparent;
  box-shadow: none;
}

.header-menu div {
  z-index: 2;
}

.page-menu {
  height: calc(100vh - 90px);
  width: 100vw;
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  overflow: auto;
  background: #fafafa;
  z-index: 100000;
}

body.menu-open {
  overflow: hidden;
}

.menu-open .header-menu {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.page-section.testimonial__section {
  background: #e1e8f5;
  background: linear-gradient(115deg, #e1e8f5, #fbf9ef);
}

.page-section.chance {
  background: #fff;
  padding-bottom: 5rem;
}

.custom-shape-divider-top-1716214474 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1716214474 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 71px;
}

.custom-shape-divider-top-1716214474 .shape-fill {
  fill: #FFFFFF;
}

.footer-wrapper {
  background: #f3f6fb;
}

/*PROJECT DETAIL*/
.project-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-detail div:nth-child(2) {
  /* height: 100%; */
}

.my-masonry-grid {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: -30px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column>div {
  margin-bottom: 30px;
}

.cyclic-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 200px);
  gap: 20px;
}

.cyclic-grid-item:nth-child(1) {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.cyclic-grid-item:nth-child(2) {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
}

.cyclic-grid-item:nth-child(3) {
  grid-column: 1 / 2;
  grid-row: 2 / 4;
}

.cyclic-grid-item:nth-child(4) {
  grid-column: 2 / 4;
  grid-row: 3 / 4;
}

.cyclic-grid-item:nth-child(5) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.cyclic-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.footer a {
  color: inherit;
}

.footer .css-jtuf4s-MuiGrid-root>.MuiGrid-item {
  padding: 0;
}

#root .plain-page-container {
  padding: 7rem 2rem 4rem;
}

.cta {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
}

.cta::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(to right, #a8a2a22e 1px, transparent 1px),
    linear-gradient(to bottom, #a8a2a22e 1px, transparent 1px);
  background-size: 14px 24px;
  mask-image: radial-gradient(ellipse 80% 50% at 50% 100%, #000 70%, transparent 110%);
}

.curve-container {
  border-radius: 1rem;
  position: relative;
  z-index: 1;
}

#root .curve-container img {
  position: relative;
  z-index: 4;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 1rem !important;
}

.curve-container::before {
  position: absolute;
  content: "";
  background-color: #878000;
  top: -30px;
  height: 100%;
  width: 100%;
  right: -20px;
  border-radius: 1rem;
  z-index: 0;
  background: rgba(135, 128, 0, 0.77);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(135, 128, 0, 0.3);
}

/**/
@media screen and (max-width: 600px) {
  .home {
    flex-direction: column;
  }

  .footer-wrapper .footer {
    max-width: 90%;
  }
}



@media screen and (max-width: 768px) {

  #root .mobile-none {
    display: none;
  }

  #root .header-menu {
    height: 90px;
  }

  #root .header-menu .nav {
    /* display: none; */
    position: fixed !important;
    height: calc(100vh - 90px);
    width: 100vw;
    top: 90px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
  }

  #root .header-menu .nav nav,
  #root .header-menu .nav nav ul {
    background: #fff;
    height: 100%;
    width: 100%;
  }

  #root .header-menu .nav nav ul {
    flex-direction: column;
    padding: 2rem 0;
  }

  #root .header-menu .nav nav ul a {
    font-size: 1.2rem;
    text-decoration: none;
  }

  #root .hero-section .info {
    width: 90%;
    max-width: 90%;
    left: 5%;
  }

  .footer .inner .MuiGrid-item {
    margin-top: 2rem;
  }

  #root .process_elements {
    row-gap: 3rem;
  }

  .the-process>p {
    max-width: 100%;
  }

  .process__section .inner p {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 900px) {
  .container .image-div {
    display: none;
  }

  .authContainer .right {
    display: none;
  }

  .authContainer .left {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-shadow: none;
    gap: 4rem;
  }
}

@media screen and (max-width: 1200px) {
  .footer-wrapper .footer {
    max-width: 100%;
  }

  #root .authContainer .right,
  #root .authContainer .left {
    border-radius: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 1200px) {
  .process_elements {
    column-gap: 2.5rem;
    row-gap: 1rem;
    max-width: 100%;
  }

  .authContainer .right {
    display: none;
  }

  .process-item {
    scale: 0.8;
  }
}

@media screen and (min-width: 1210px) {
  .hero-section {
    height: 70vh;
  }

  .process_elements {
    column-gap: 5rem;
    row-gap: 3rem;
    max-width: 90%;
  }

  .process-item {
    scale: 0.9;
  }
}


@media screen and (max-width: 768px) {
  .info h2 {
    font-size: 2.5rem;
  }

  .hero-section-2 {
    /* height: 90vh; */
    max-width: 100vw;
  }

  .hero-section-2 .info {
    padding-top: 8rem;
  }
}

@media screen and (max-width: 380px) {
  .info h2 {
    font-size: 2.4rem;
  }

  .hero-section-2 {
    height: 90vh;
  }

  .hero-section-2 .info {
    max-width: 100%;
    padding-top: 5rem;
  }
}